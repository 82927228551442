import './nav'
import './video'
import '../shared/phone'
import './platinium'
import './top_nav'
import './mobile_partner_nav'
import './horizontal_scroll'
import '../shared/buyers_page_personalizer'

import '../shared/h4h_form'
import AddToCart from '../full-gpe/cart/add-to-cart'
import { store } from'../default/store'

import AddToCartForms from '../shared/add_to_cart_forms'
import HotelIndex from '../shared/add_to_cart_forms/h4h_hotels/hotel_index.vue'
import CartNav from '../shared/nav/nav_cart'
import Checkout from '../shared/checkout'
import AbTests from '../shared/ab_tests'
import DepositRedemptions from '../shared/deposit_redemptions'
import PackageTabs from '../shared/package_tabs'
import PricingFilterButtons from './pricing_filter/pricing_filter_buttons'

import VueLoader from '../default/vue-loader'
import PriceFilter from '../shared/price_filter'
import './ticket_seating_chart'
import './seating_chart_filter'
import '../shared/hide_map'
import '../shared/disable_reset'
import '../shared/package_comparisons'
import '../shared/hide_sign_in_on_inital_visit'
import './tooltip'
import './slide_elems'

document.addEventListener('turbo:load', () => {
  AddToCartForms.loadBottomForm()
  CartNav.addCartToNavbar()
  DepositRedemptions.findCustomerDeposits()
  Checkout.loadWithAddOn()
  AddToCart.AddToCart()
  AbTests.loadHotelAbTests()
  AbTests.loadF1PackageAbTests()

  PackageTabs.loadTabs(782)

  if (document.getElementById('packge-price-filer')) {
    PricingFilterButtons.loadPricingFilters()
  }

  if(document.getElementById('seating_chart_price')) {
    VueLoader.loadVueComponent(PriceFilter, document.getElementById('seating_chart_price'), store)
  }

  VueLoader.loadVueComponent(HotelIndex, document.getElementById('hotelIndex'), store)
})
